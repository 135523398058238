export const footerItems = [
  {
    id: '1',
    value: 'Twitter',
    link: 'https://twitter.com/AnnikaTerwey'
  },
  {
    id: '2',
    value: 'Vimeo',
    link: 'https://vimeo.com/annikaterwey'
  },
  {
    id: '3',
    value: 'Instagram',
    link: 'https://www.instagram.com/dunkleecken/'
  }
];
